<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="颜色名称">
					<c-input name="color_name"></c-input>
				</m-search-item>
			</m-search>

			<m-operate>
				<c-button v-if="auth('b_color_edit')" color="sys" @click="createColor">新建颜色</c-button>
			</m-operate>

			<c-table ref="table" height="grow">

				<c-table-column
					label="颜色"
					width="400"
					name="color_name"
				>
					<template v-slot="{data}">
						<div class="flex-center-cross">
							<div :style="{'background-color': data.color_value}" style="width:20px;height:20px;margin-right:5px"></div>
							<span>{{data.color_name}}</span>
						</div>
					</template>
				</c-table-column>
				
				<c-table-column
					label="创建人"
					width="120"
					name="create_user_realname"
				></c-table-column>
				
				<c-table-column
					label="创建时间"
					width="140"
					name="create_date"
				></c-table-column>

				<c-table-column
					type="button"
					label="操作"
					width="120"
				>
					<template v-slot="{data}">
						<c-table-button v-if="auth('b_color_edit')" @click="editColor(data)">编辑</c-table-button>
						<c-table-button v-if="auth('b_color_edit')" @click="deleteColor(data)">删除</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>

		<!--新增、编辑 弹框-->
		<c-dialog ref="colorEdit" :title="editState ? '编辑颜色' : '新建颜色'" width="500" @resolve="submitColor">
			<c-form ref="colorForm" unit-width="100%">
				<c-form-item label="颜色名称" required>
					<c-input name="color_name" maxlength="200"></c-input>
				</c-form-item>
				<c-form-item label="颜色">
					<c-color name="color_value" ></c-color>
				</c-form-item>
				<c-input type="hidden" name="id"></c-input>
			</c-form>
		</c-dialog>

  </page>
</template>

<script>
import {cryptoJs} from '@deanwu/vue-component-library'
import {mapState} from 'vuex'

export default {
	name: 'm_color',
	components: {
    
	},
	data() {
		return {
		  editState: false
		}
	},
	computed: {
		...mapState(['userInfo'])
	},
	mounted() {
		this.$refs.search.search();
	},
	methods: {
		//搜索
		searchHandle(data) {
			data.type = 1;
			this.$refs.table.load({
				url: '/dic/color/list',
				data
			});
		},
		//新建颜色
		createColor() {
			this.editState = false;
			this.$refs.colorForm.clear();
			this.$refs.colorEdit.open();
		},
		//编辑颜色
		editColor(val) {
			this.editState = true;
			this.$refs.colorForm.set(val);
			this.$refs.colorEdit.open();
		},
		//删除颜色
		deleteColor(val){
			this.$confirm({
				title: '确认',
				message: '确定要删除【'+val.color_name+'】吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/dic/color/delete',
						data: {id:val.id},
						loading: true,
						success: data => {
							this.$message({
							  message: '删除成功',
							  type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//提交保存
		submitColor(stop) {
			stop();
			this.$refs.colorForm.submit({
				url: this.editState ? '/dic/color/edit' : '/dic/color/add',
				rule: {
					color_name: '请填写颜色名称',
					color_value: '请选择颜色'
				},
				dataFormatter: data => {
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.$refs.table.update();
					this.$refs.colorEdit.close();
				}
			});
		},
  }
}
</script>